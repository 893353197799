
import { defineComponent, PropType } from "vue";
import { IObjectKeys } from "@/interfaces/IObjectKeys";
import TableRow from "@/components/Table/TableRow.vue";

import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { mapTableRowsToTableContent } from "@/hooks/tables/modules/baseTable/mapTableRowsToTableContent";
import AppTableBodyCell from "@/components/Table/ts/AppTableBodyCell.vue";
import AppTableLayout from "@/components/Table/ts/AppTableLayout.vue";

const tableStatuses: IObjectKeys = {
  verified: "base",
  unverified: "warn",
  blocked: "error"
};

export type tableHeadCellsList = ITableHeadCell[];
export type tableBodyList = ITableBodyRow[];

export default defineComponent({
  name: "AppTable",
  components: {
    AppTableLayout,
    AppTableBodyCell,
    TableRow
  },
  inheritAttrs: false,
  props: {
    paginationCaptureKey: {
      type: String,
      default: ""
    },
    totalRows: { type: Number as PropType<number>, default: 0 },
    perPage: { type: Number, default: 10 },
    currentPage: { type: Number, default: 1 },
    tableHead: {
      type: Array as PropType<tableHeadCellsList>,
      required: true
    },

    tableBody: {
      type: Array as PropType<tableBodyList>,
      required: true
    },

    hideButton: { type: Boolean, default: false },
    spaceBetween: { type: Boolean, default: true },
    withFooter: { type: Boolean, default: true }
  },
  emits: {
    changeViewConfiguration: null,
    sort: null,
    deleteItem: null,
    editItem: null
  },
  computed: {
    withMoreMenu(): boolean {
      return !!this.tableBody[0]?.actions;
    },

    updatedBodyRows(): tableBodyList {
      return this.tableBody.map(row => {
        const status = row.status || "base";

        return {
          ...row,
          cells: row.cells.map((cell, idx) => ({
            ...cell,
            size: this.tableHead[idx]?.size
          })),
          withAction: !!row.onClick,
          status: tableStatuses[status] || "base"
        };
      });
    },

    mappedRows() {
      return mapTableRowsToTableContent(this.updatedBodyRows);
    }
  },

  methods: {
    deleteItem(id: number) {
      this.$emit("deleteItem", id);
    },
    editItem(id: number) {
      this.$emit("editItem", id);
    }
  }
});
